@media (min-width: 992px) {
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}


.sebm-google-map-container {
  height: 300px;
}

#map {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
  margin-top: 70px;
}

.places-buttons .btn {
  margin-bottom: 30px;
}

.space-70 {
  height: 70px;
  display: block;
}

.tim-row {
  margin-bottom: 20px;
}
.sidebar .logo-img img {
    width: 180px!important;
    left: 38px!important;
}
.tim-typo {
  padding-left: 25%;
  margin-bottom: 40px;
  position: relative;
}

.tim-typo .tim-note {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
}

.tim-row {
  padding-top: 50px;
}

.tim-row h3 {
  margin-top: 0;
}

@media (min-width: 992px) {
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}

.fw-600 {
  font-weight: 600 !important;
}

.nav-link {
  cursor: pointer;
}

.sidebar .nav p {
  margin: 0;
  line-height: 30px;
  font-size: 14px;
  position: relative;
  display: block;
  height: auto;
  white-space: nowrap;
  color: rgba(255, 255, 255, .6)!important;
}

.sidebar
  .sidebar-wrapper
  > .nav
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-mini,
.sidebar
  .sidebar-wrapper
  .user
  .user-info
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-mini {
  text-transform: uppercase;
  width: 30px;
  margin-right: 15px;
  text-align: center;
  letter-spacing: 1px;
  position: relative;
  float: left;
  display: inherit;
  color: #79829c !important;
}

.sidebar
  .sidebar-wrapper
  > .nav
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal,
.sidebar
  .sidebar-wrapper
  .user
  .user-info
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal {
  margin: 0;
  position: relative;
  transform: translate(0);
  opacity: 1;
  white-space: nowrap;
  display: block;
  color: #79829c !important;
}

.sidebar .nav li.active > a,
.sidebar .nav li.active > a i,
.sidebar .nav li.active > a p {
  color: #79829c !important;
}
.sidebar .nav a {
  padding-left: 10px; /* Default padding */
  transition: padding-left 0.3s ease; /* Smooth transition */
}

.sidebar .nav a:hover {
  padding-left: 20px; /* Increased padding on hover */
}


.sidebar[data-color="white"] li.active > a {
  background-color: #fff;
  box-shadow: 0 4px 20px #00000024, 0 7px 10px -5px #fff6;
  /* color: rgba(255, 255, 255, .6) !important; */
  font-weight: 800 !important;
}

.btn-primary {
  background-color: #6658dd !important;
  color: #ffffff !important;
  font-weight: 800;

  border-color: #6658dd !important;
}

.btn-secondary {
  background-color: #0f1114 !important;
  color: #ffffff !important;
  font-weight: 800;

  border-color: #0f1114 !important;
}

.card .card-header.card-header-icon .card-title {
  color: #0f1114 !important;
  text-transform: uppercase;
  font-weight: 900 !important;
}

.card [class*="card-header-"] .card-icon,
.card [class*="card-header-"] .card-text {
  border-radius: 3px;
  padding: 10px;
  margin-top: 0px;
  margin-right: 15px;
  float: left;
  background: none;
background:  none!important;
  background-color: #6658dd!important;
 

}

.card [class*="card-header-"] .card-icon{
  padding: 6px!important;
  margin-top: 8px!important;
}
.card-two {
  border-radius: 3px;
  background-color: #e4f3f6;
  padding: 15px;
  margin-top: -20px;
  margin-right: 15px;
  float: left;
  background: #e1e7fc !important;
  color: #5177ee;
}

.card-three {
  border-radius: 3px;
  background-color: #e4f3f6;
  padding: 15px;
  margin-top: -20px;
  margin-right: 15px;
  float: left;
  background: #eaebec !important;
  color: #2d3032;
}

.card-four {
  border-radius: 3px;
  background-color: #e4f3f6;
  padding: 15px;
  margin-top: -20px;
  margin-right: 15px;
  float: left;
  background: #eff0f7 !important;
  color: #505357;
}

.card-five {
  border-radius: 3px;
  background-color: #e4f3f6;
  padding: 15px;
  margin-top: -20px;
  margin-right: 15px;
  float: left;
  background: #e4f3f6 !important;
  color: #9cd2dc;
}

thead{
  border-bottom: 2px solid #dee2e6;
}
.table > thead > tr > th {
  border-bottom-width: 1px;
  font-size: 14px !important;
  color: #6c757d !important;

  font-weight: 900 !important;
}

.table > tbody > tr > td {
  color: #6c757d;
  font-weight: 400 !important;
}

.text-primary {
  color: #556ee6 !important;
}

.navbar .navbar-brand {
  color: #878a99 !important;
  font-weight: 900 !important;
}

.navbar {
  background-color: #fff;
  -webkit-box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03)!important;
  box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03)!important;
}

.main-panel .main-content {
  margin-top: 70px;
  padding: 30px 15px;
  background-color: #f5f6f8 !important;
  min-height: calc(100vh - 123px);
}



.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-size: unset !important;
}

.invalid-feedback {
  display: none;
  width: 100%;
  /* margin-top: -1.75rem; */
  font-size: 0.875em;
  color: #f44336;
  /* margin-top: -40px !important; */
  margin-bottom: 10px !important;
  font-weight: 500;
}

.sidebar .nav i {
  font-size: 24px;
  float: left;
  margin-right: 15px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  color: rgba(255, 255, 255, .6)!important;
}
.thead-light{
  background-color: #eff2f7;
  color: #000000;
}
.table-responsive {
  overflow-x: auto!important;
  -webkit-overflow-scrolling: touch!important;
}

.fs-20px{
  font-size: 20px!important;
}
.form-control{
  border: 1px solid #e3e7f0!important;
  border-radius: 4px!important;
  height: 44px!important;
  padding-left: 10px!important;
}
.form-control, .is-focused .form-control {
  background-image: none!important;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #556ee6!important;
  --mdc-protected-button-label-text-color: #fff;
}
.p-4{
  padding: 2.25rem !important;
}
.text-muted-ct {
  --ct-text-opacity: 1;
  color: #98a6ad;
  line-height: 17px;
  font-weight: 400;

}
.form-label{
  color: #6c757d;
}
/* :root .btn {
  --ct-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
  --ct-btn-active-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-link-color: #6658dd;
  --ct-btn-link-hover-color: #473e9b;
  --ct-btn-link-disabled-color: #98a6ad;
} */
.btn{
box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);

}

/* tables */
.header-title {

  margin: 0 0 7px 0;

  line-height: 1.1;
  color: #343a40;
  font-size: 22px;
  font-weight: 600;
}
.pt-20{
  padding-top: 20px;
}
.ngx-pagination .current {
  padding: .1875rem .625rem;
  background: #6658dd!important;
  color: #fefefe;
  cursor: default;
  border-radius: 50%;
}

.btn-light-ct{
  background-color: #f3f7f9;
  color: #343a40;
  border-color: #f3f7f9;
}